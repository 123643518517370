import { Component, OnInit, Output, ViewChild, EventEmitter, TemplateRef, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import {
  EmailTemplateResponse, EventInfoResponse, ParticipantAdminControllerService, ParticipantResponse,
  SendScheduleControllerService, SendSchedulePreviewResponse
} from '@ent-regis/entregis-ts-angular';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { filter } from 'rxjs/operators';
import { EventService } from '../../../layout/event/event.service';

@Component({
  selector: 'app-send-schedule-modal',
  templateUrl: './send-schedule-modal.component.html',
  styleUrls: ['./send-schedule-modal.component.scss']
})
export class SendScheduleModalComponent implements OnInit {
  templateId: number;
  templates: EmailTemplateResponse[] = [];
  registerCompanyId: number;
  registerInvestorId: number;
  eventType: EventInfoResponse.EventTypeEnum;
  isDigitalRoadShowAndCompanyType: boolean = false;
  templateType: any;
  participants: ParticipantResponse[];
  previewSelectedId: number;
  selectedParticipants: number[] = [];
  selectedPreviewParticipants: ParticipantResponse[] = [];
  preview: SendSchedulePreviewResponse;
  public content: SafeHtml;
  bsModalRef: BsModalRef;
  @ViewChild('template', { static: false }) template: TemplateRef<any>;

  @Output() send = new EventEmitter<boolean>(null);

  constructor(
    private modalService: BsModalService,
    private eventService: EventService,
    private sendScheduleService: SendScheduleControllerService,
    private participantService: ParticipantAdminControllerService,
    private sanitizer: DomSanitizer,
    private toastr: ToastrService,
  ) { }

  ngOnInit() { }

  openModal(): void {
    this.templateId = null;
    this.preview = null;
    this.getTemplates();
    this.bsModalRef = this.modalService.show(this.template, { class: 'modal-xl', ignoreBackdropClick: true });
  }

  close() {
    this.bsModalRef.hide();
  }

  getTemplates(): void {
    this.templateType = this.registerCompanyId ? 'participantSchedule' : 'investorSchedule';
    this.eventService.event.pipe(filter(x => x != null)).subscribe(event => {
      this.eventType = event.eventType;
      this.sendScheduleService.getAllEmailTemplateUsingGET1(event.eventId, this.templateType)
        .subscribe((res) => {
            this.templates = res.data;
          }
        );
    });
  }

  selectTemplate(): void {
    if (this.templateType === 'participantSchedule') {
      this.participantService.getParticipantUsingGET(this.registerCompanyId)
        .subscribe((res) => {
          this.participants = res.data.filter(f => !f.hasNotAppUser);
          this.selectedParticipants = this.participants.map(m => m.participantId);
          this.selectedPreviewParticipants = this.participants;
          if (this.participants.length > 0) {
            this.previewSelectedId = this.participants[0].participantId;
            this.selectPreviewTemplate();
          }
        });
    } else {
      this.previewSelectedId = this.registerInvestorId;
      this.selectPreviewTemplate();
    }
  }

  onSelectedParticipants(): void {
    this.selectedPreviewParticipants = this.participants.filter(f => this.selectedParticipants.includes(f.participantId));
  }

  selectPreviewTemplate(): void {
    if (this.templateId && this.previewSelectedId) {
      this.sendScheduleService.previewUsingGET(this.previewSelectedId, this.templateId)
        .subscribe(res => {
          this.preview = res.data;
          this.content = this.sanitizer.bypassSecurityTrustHtml(this.preview.previewBody);
        });
    }
  }

  submit(): void {
    if (this.templateType === 'participantSchedule') {
      if (this.selectedPreviewParticipants.length > 0) {
        const calls = [];
        const processedEmails = new Set<string>();
        this.selectedPreviewParticipants.forEach((participant) => {
          if (processedEmails.has(participant.email)) {
            calls.push(this.sendScheduleService.sendUsingPOST(participant.participantId, this.templateId, true));
          } else {
            calls.push(this.sendScheduleService.sendUsingPOST(participant.participantId, this.templateId, false));
            processedEmails.add(participant.email);
          }
        });
        forkJoin(calls).subscribe(() => {
          this.send.emit(true);
        }, () => {
          this.send.emit(false);
        });
      } else {
        this.toastr.error("Please select receiver at least 1 person.");
      }
    } else {
      this.sendScheduleService.sendUsingPOST(this.previewSelectedId, this.templateId)
        .subscribe(
          () => {
            this.send.emit(true);
          }, () => {
            this.send.emit(false);
          }
        );
    }
  }
}
